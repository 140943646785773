import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  DateTimeField,
  LongTextField,
  NumberField,
  StringField,
} from "../plugins/Cruxtify/field-types";
import {
  DateTimeProperty,
  NumberProperty,
  StringProperty,
} from "../plugins/Cruxtify/property-types";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";

export class GoldPricingProfile extends CrudModel {
  protected static _typeLabel = "Gold Pricing Profile";
  protected static asProperty = "gold_pricing_profile";
  public static api = {
    path: "gold-pricing-profiles",
    fullCache: true,
  };
  protected static routeBase = "gold-pricing-profiles";

  protected userIdField = [];

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
    },
    UserPermission.Read
  );

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Created At",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Updated At",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: StringProperty,
      opts: {
        label: "Label",
        name: "label",
      },
    },
    {
      type: "BooleanProperty",
      opts: {
        label: "Published",
        name: "published",
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "Gold Profit (% as decimal)",
        name: "gold_profit",
        decimals: 3,
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "Gold Excess (% as decimal)",
        name: "gold_excess",
        decimals: 3,
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "Cost of Gold (USD)",
        name: "cost_of_gold",
      },
    },
    {
      type: StringProperty,
      opts: {
        label: "Comments",
        name: "comments",
      },
    },
  ];

  protected static fieldDefinitions = [
    {
      type: NumberField,
      opts: {
        property: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeField,
      opts: {
        property: "created_at",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeField,
      opts: {
        property: "updated_at",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: StringField,
      opts: {
        property: "label",
        isPrimaryLabelField: true,
      },
    },
    {
      type: "BooleanField",
      opts: {
        property: "published",
      },
    },
    {
      type: NumberField,
      opts: {
        property: "gold_profit",
      },
    },
    {
      type: NumberField,
      opts: {
        property: "gold_excess",
      },
    },
    {
      type: NumberField,
      opts: {
        property: "cost_of_gold",
      },
    },
    {
      type: LongTextField,
      opts: {
        property: "comments",
      },
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      useModal: true,
    },
  ];
}
