
export const RESULT_TYPES = {
  ORIGINAL_IS_DIRECT: {
    value: "original_is_direct",
    label: "Original Query: Direct Match",
  },
  GENERALIZED_PART_NUMBER: {
    value: "generalized_part_number",
    label: "Generalized Part Number",
  },
  AUTOCORRECTED_PART_NUMBER: {
    value: "autocorrected_part_number",
    label: "Autocorrected Part Number",
  },
  AUTOCORRECTED_IS_DIRECT: {
    value: "autocorrected_is_direct",
    label: "Autocorrected Is Direct",
  },
  PARSED_PART_NUMBER: {
    value: "parsed_part_number",
    label: "Parsed Part Number",
  },
  PARSING_ERROR: {
    value: "parsing_error",
    label: "Parsing Error",
  },
  PRICING_ERROR: {
    value: "pricing_error",
    label: "Pricing Error",
  },
  PRICED_COMPONENTS: {
    value: "priced_components",
    label: "Priced Components",
  },
  PRICING_SCHEMA: {
    value: "pricing_schema",
    label: "Pricing Schema",
  },
  COMPONENT_MAPPINGS: {
    value: "component_mappings",
    label: "Component Mappings",
  },
  BASE_PRICE: {
    value: "base_price",
    label: "Base Price",
  },
  MAX_MOQ: {
    value: "max_moq",
    label: "MOQ",
  },
  GROUP_DISCOUNT_FACTOR: {
    value: "group_discount_factor",
    label: "Group Discount Factor",
  },
  ADDERS_SETUP_AMOUNT: {
    value: "adders_setup_amount",
    label: "Adders Setup Total",
  },
  ADDERS_BASE_AMOUNT: {
    value: "adders_base_amount",
    label: "Adders Base Total",
  },
  USE_ADDER: {
    value: "use_adder",
    label: "Use Adder",
  },
  USE_FACTOR: {
    value: "use_factor",
    label: "Use Factor",
  },
  EXCHANGE_RATE: {
    value: "exchange_rate",
    label: "Exchange Rate",
  },
  OEM_PRICE: {
    value: "oem_price",
    label: "OEM Price",
  },
  FACTORS_LEAD_TIME: {
    value: "factors_lead_time",
    label: "Factors Additional Lead Time",
  },
  ADDERS_LEAD_TIME: {
    value: "adders_lead_time",
    label: "Adders Additional Lead Time",
  },
  MAX_CLASS: {
    value: "max_class",
    label: "Max Class",
  },
  PRODUCT_GROUP: {
    value: "product_group",
    label: "Product Group",
  },
  SERIES: {
    value: "series",
    label: "Series",
  },
};

export const getResultsByType = function (resultResponse, getTypes) {
  if (!Array.isArray(getTypes)) {
    getTypes = [getTypes];
  }

  return resultResponse.logic_monitor
    .filter((result) =>
      getTypes.map((type) => type.value).includes(result.decision)
    )
    .map((result) => result.result);
};

export default {
  data() {
    return {
      activeTab: 0,
      tabs: [
        { title: "Results", component: "results" },
        { title: "Structure", component: "structure" },
        { title: "Logic", component: "logic" },
        { title: "Adders", component: "adders" },
      ],
    };
  },
  props: {
    singleJsonResult: Object,
  },
  computed: {
    errorMsgs() {
      if (this.singleJsonResult.pricing) return [];

      return getResultsByType(this.singleJsonResult, [
        RESULT_TYPES.PARSING_ERROR,
        RESULT_TYPES.PRICING_ERROR,
      ]);
    },
  },
};
