import { CrudField } from "../plugins/Cruxtify/CrudField";

export class MosCustomAddersField extends CrudField {
  public static fieldName = "MosCustomAddersField";
  public static formComponentName: string = "mos-custom-adders-field";
  public static viewComponentName: string = "mos-custom-adders-field";

  protected _label: string = "Custom Adders";
  protected _requiresDetachedLabel: boolean = true;
}
