import { CrudField } from "../plugins/Cruxtify/CrudField";

export class MosPricingResultField extends CrudField {
  public static fieldName = "MosPricingResultField";
  public static formComponentName: string = "mos-pricing-result-field";
  public static viewComponentName: string = "mos-pricing-result-field";

  protected _label: string = "Pricing Results";
  protected _requiresDetachedLabel: boolean = false;
}
